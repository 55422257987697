import { useEffect, useState } from 'react';
import { toZonedTime } from 'date-fns-tz';

type CurrentTimeOutput = {
  currentTime: Date;
  updateCurrentTime: () => void;
};

export const useCurrentTime = (intervalMs = 5000): CurrentTimeOutput => {
  const [currentTime, setCurrentTime] = useState<Date>(toZonedTime(new Date(), 'Europe/Vienna'));

  function updateCurrentTime() {
    setCurrentTime(toZonedTime(new Date(), 'Europe/Vienna'));
  }

  useEffect(() => {
    const updateInterval = setInterval(updateCurrentTime, intervalMs);
    return () => clearInterval(updateInterval);
  }, [intervalMs]);

  return { currentTime, updateCurrentTime };
};
